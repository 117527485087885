<template>
  <div class="home">
    <!-- <div class="btn" @click="auth">点击授权登录</div> -->
    <!-- <img src="../assets/qrcode.jpg" alt="">
    <p class="tips1">(长按识别二维码)</p>
    <p class="tips">请长按识别关注公众号后，点击公众号的菜单绑定<br>
      避免因未关注而授权失败</p> -->
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API 
    }
  },
  mounted (opt) {
    console.log(this.baseUrl);
    // if (opt.hasOwnProperty('code')) {
      
    // }else {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1b56cb00c92d20c5&redirect_uri='+
                              encodeURIComponent('https://car.lanlitao.net/openWechat')+'&response_type=code&scope=snsapi_userinfo&state=#wechat_redirect'
  },
  methods: {
    getQueryString(name) { 
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
      var r = window.location.search.substr(1).match(reg); 
      if (r != null) return unescape(r[2]); 
      return null; 
    },
    // 授权
    auth(){
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1b56cb00c92d20c5&redirect_uri='+
                              encodeURIComponent('https://car.lanlitao.net/openWechat')+'&response_type=code&scope=snsapi_userinfo&state=#wechat_redirect'
    },
    getAuthResult(){
      const that = this
      let _code = that.getQueryString('code')
      that.axios({
        url: that.baseUrl + '/updateCdoeWechatMsg',
        method: 'get',
        params: {token: that.token}
      }).then(res => {
        console.log(res)
      })
    }
  }, 
}
</script>
<style scoped>
div {
  margin: 0;
}
.home {
  height: 100vh;
  padding-top: 30px;
  background-color: #f5f5f5;
}
.tips1{
  margin-top: 10px;
  font-size: 0.75rem;
  color: #888888;
  line-height: 1em;
}
.tips2{
  margin: 20px 0;
  font-size: 0.875rem;
  color: #888888;
  line-height: 1.6em;
}
</style>
